import React from 'react';
import styled, { keyframes } from 'styled-components';

const shine = keyframes` 
  0% {
    background-position-x: 0;
  }
  
  100% {
    background-position-x: 275px;  
  }
`;

const shineXs = keyframes` 
  0% {
    background-position-x: 0;
  }
  
  100% {
    background-position-x: 384px;  
  }
`;

const shineSm = keyframes` 
  0% {
    background-position-x: 0;
  }
  
  100% {
    background-position-x: 623px;  
  }
`;

const Wrapper = styled.div`
  text-transform: capitalize;
  line-height: 1;
  font-size: 2.15rem;
  font-family: ${props => props.theme.typo.main.fontFamily};
  font-weight: ${props => props.theme.typo.main.fontWeight};

  background: linear-gradient(
    to right,
    ${props => props.theme.palette.greyDark} 0,
    ${props => props.theme.palette.primary.text} 10%,
    ${props => props.theme.palette.greyDark} 20%
  );

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-size-adjust: none;
  background-size: 100%;
  animation-name: ${shine};
  animation-duration: 7s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @media (min-width: ${props => props.theme.breakpoints.xs}) {
    font-size: 3.35rem;
    animation-name: ${shineXs};
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    line-height: 1;
    animation-name: ${shineSm};
  }
`;

export default function KabbalahSubtitle({ text }) {
  return <Wrapper>{text}</Wrapper>;
}
