import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  font-family: ${props => props.theme.typo.accent.fontFamily};
  font-weight: ${props => props.theme.typo.accent.fontWeight};
  line-height: ${props => props.theme.typo.accent.lineHeight};
  letter-spacing: ${props => props.theme.typo.accent.letterSpacing};
  text-transform: capitalize;

  &.hidden .ghost {
    opacity: 0;
    max-width: 0;
  }
`;

const Letter = styled.span`
  display: inline-block;
  max-width: 1.5em;
  font-size: 2.6rem;
  color: ${props => props.theme.palette.primary.text};
  transition-property: color, opacity, max-width;

  transition-duration: ${props => props.theme.animate.theme.duration},
    ${props => props.theme.animate.letter.duration},
    ${props => props.theme.animate.letter.duration};

  transition-timing-function: ${props => props.theme.animate.theme.function},
    ${props => props.theme.animate.letter.function},
    ${props => props.theme.animate.letter.function};

  transition-delay: 0s, ${props => props.theme.animate.letter.delay},
    ${props => props.theme.animate.letter.delay};

  &.ghost {
    opacity: 1;
    max-width: 1.5em;
  }

  @media (min-width: ${props => props.theme.breakpoints.xs}) {
    font-size: 3.6rem;
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 5.9rem;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    font-size: 8rem;
  }
`;

const KabbalahSubtitle = () => {
  const [isTitleExtended, setIsTitleExtended] = useState(false);

  useEffect(() => {
    setIsTitleExtended(true);
  }, []);
  return (
    <Wrapper className={isTitleExtended ? '' : 'hidden'}>
      <Letter>d</Letter>
      <Letter className={'ghost'}>a</Letter>
      <Letter className={'ghost'}>a</Letter>
      <Letter className={'ghost'}>t</Letter>
      <Letter>.</Letter>
      <Letter>a</Letter>
      <Letter className={'ghost'}>g</Letter>
      <Letter className={'ghost'}>e</Letter>
      <Letter className={'ghost'}>n</Letter>
      <Letter className={'ghost'}>c</Letter>
      <Letter className={'ghost'}>y</Letter>
    </Wrapper>
  );
};

export default KabbalahSubtitle;
