import React from 'react';
import styled from 'styled-components';
import { Layout } from '../components';
import KabbalahSubtitle from '../components/kabbalah-for-the-nations/KabbalahSubtitle';

const KabbalahWrapper = styled.div`
  max-width: 260px;
  margin: 0 auto;
  padding: 140px 32px 64px;
  font-family: ${props => props.theme.typo.main.fontFamily};
  font-size: 0.8rem;
  line-height: 1.75;
  text-align: center;
  color: ${props => props.theme.palette.primary.text};

  @media (min-width: ${props => props.theme.breakpoints.xs}) {
    max-width: 400px;
    font-size: 1rem;
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 700px;
    padding-top: 130px;
    font-size: 1.1rem;
  }
`;

const KabbalahTitle = styled.div`
  font-family: ${props => props.theme.typo.accent.fontFamily};
  font-weight: ${props => props.theme.typo.accent.fontWeight};
  line-height: ${props => props.theme.typo.accent.lineHeight};
  letter-spacing: 2px;
  text-transform: capitalize;
  font-size: 3.8rem;
  opacity: 0;
  transition-property: opacity;
  transition-duration: ${props =>
    props.theme.animate.bodyLoaded.title.duration};
  transition-timing-function: ${props =>
    props.theme.animate.bodyLoaded.title.function};

  @media (min-width: ${props => props.theme.breakpoints.xs}) {
    font-size: 6.2rem;
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 10rem;
  }

  .body--loaded & {
    opacity: 1;
  }
`;

const KabbalahSubtitleWrapper = styled.div`
  opacity: 0;
  transition-property: opacity;
  transition-duration: ${props =>
    props.theme.animate.bodyLoaded.title.duration};
  transition-timing-function: ${props =>
    props.theme.animate.bodyLoaded.title.function};
  margin-bottom: 16px;

  @media (min-width: ${props => props.theme.breakpoints.xs}) {
    margin-bottom: 24px;
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    margin-bottom: 32px;
  }

  .body--loaded & {
    opacity: 1;
  }
`;

const KabbalahContent = styled.div`
  opacity: 0;
  transition-property: opacity;
  transition-duration: ${props =>
    props.theme.animate.bodyLoaded.subtitle.duration};
  transition-timing-function: ${props =>
    props.theme.animate.bodyLoaded.subtitle.function};
  transition-delay: ${props => props.theme.animate.bodyLoaded.subtitle.delay};

  .body--loaded & {
    opacity: 1;
  }
`;

const KabbalahAbout = styled.p`
  display: inline-block;
  margin: 0 auto 32px;

  font-family: ${props => props.theme.typo.accent.fontFamily};
  font-size: 1.1rem;
  line-height: 1.5;
  text-align: center;

  @media (min-width: ${props => props.theme.breakpoints.xs}) {
    font-size: 1.7rem;
    line-height: 1.35;
    margin-bottom: 40px;
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 2rem;
    margin-bottom: 48px;
  }
`;

const KabbalahExplanation = styled.p`
  margin: 32px auto;

  @media (min-width: ${props => props.theme.breakpoints.xs}) {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    margin-top: 48px;
    margin-bottom: 48px;
  }
`;

const Link = styled.a`
  display: block;
  padding-bottom: 4px;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.palette.primary.text};
  transition: transform 1s;
`;

const Line1 = styled(Line)`
  transform: translateX(0);
`;

const Line2 = styled(Line)`
  position: relative;
  bottom: 0;
  transform: translateX(-200%);
`;

const KabbalahLink = styled.div`
  display: inline-block;
  position: relative;
  overflow: hidden;

  &:hover ${Line1} {
    transform: translateX(200%);
  }

  &:hover ${Line2} {
    transform: translateX(0);
  }
`;

const Kabbalah = () => {
  return (
    <Layout>
      <KabbalahWrapper>
        <KabbalahTitle>Kabbalah</KabbalahTitle>
        <KabbalahSubtitleWrapper>
          <KabbalahSubtitle text="for the nations" />
        </KabbalahSubtitleWrapper>
        <KabbalahContent>
          <KabbalahAbout>
            Unique base course of 10 lessons based on books of rabbi Yitzchak
            Ginsburgh and Baal HaSulam. Adapted for non-Jews audience.
          </KabbalahAbout>
          <KabbalahLink>
            <Link
              href="https://prezi.com/view/C5HoM5W1nueflENp3RAC/"
              target="_blank"
            >
              Watch presentation
            </Link>
            <Line1 />
            <Line2 />
          </KabbalahLink>
          <KabbalahExplanation>
            Since 2020, several hundred Russian-speaking students have
            successfully completed the course. We are currently adapting the
            information for an English-speaking audience and therefore
            recruiting a small group of students for free individual lessons.
            Write us to receive more details.
          </KabbalahExplanation>
          <KabbalahLink>
            <Link href="mailto:hello@daat.agency" target="_blank">
              hello@daat.agency
            </Link>
            <Line1 />
            <Line2 />
          </KabbalahLink>
        </KabbalahContent>
      </KabbalahWrapper>
    </Layout>
  );
};

export default Kabbalah;
